import { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { CgSpinner } from 'react-icons/cg';
import { useTranslation } from 'react-i18next';

import DPCoin from './DPCoin';
import SlopeCard from './SlopeCard';
import SlopeButton from './SlopeButton';
import SlopeProgressBar from './SlopeProgressBar';

const ChallengeItem = ({ id, title, points, progress, isCompleted, condition, onClaim }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleClaim = () => {
    setIsLoading(true);

    onClaim(id);

    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  };

  return (
    <SlopeCard
      leftContent={
        <div className="task-icon">
          {condition?.type === 'invite' ? (
            <img src="/icon_users.svg" alt={t('Distance Driven')} />
          ) : (
            <img src="/icon_road.svg" alt={t('Distance Driven')} />
          )}
        </div>
      }
      rightContent={
        <div className={'task-item'}>
          <div className="task-details">
            <span className="task-title">{title}</span>
            <DPCoin points={points} />
          </div>

          <div className="task-action">
            {condition?.type === 'invite' &&
            typeof progress === 'number' &&
            progress < condition?.friends ? (
              <div className="challenge-progress">
                <span className="progress-label">
                  {t('{{progress}}/{{friends}}', { progress, friends: condition?.friends })}
                </span>
              </div>
            ) : condition?.type === 'distance' && typeof progress === 'number' && progress < 100 ? (
              <div className="challenge-progress">
                <span className="progress-label">{t('{{progress}}%', { progress })}</span>

                <SlopeProgressBar progress={progress} color="color-accent-secondary" />
                {/* <div className="progress-bar">
                  <div className="progress" style={{ width: `${progress}%` }}></div>
                  {/* <span className="progress-label">{t('{{progress}}%', { progress })}</span> 
                </div> 
                  */}
              </div>
            ) : (
              <>
                {!isCompleted ? (
                  isLoading ? (
                    <CgSpinner size={30} className="task-spinner" />
                  ) : (
                    <SlopeButton
                      variant="secondary"
                      size="sm"
                      onClick={handleClaim}
                      disabled={isCompleted}
                    >
                      {t('Claim')}
                    </SlopeButton>
                  )
                ) : (
                  <FaCheck size={30} className="color-accent-primary" />
                )}
              </>
            )}
          </div>
        </div>
      }
    ></SlopeCard>
  );
};

export default ChallengeItem;
