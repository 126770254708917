import '../assets/styles/DPCoin.css';

const DPCoin = ({ points, ...props }) => {
  return (
    <div className="coin-component" {...props}>
      <img src="/icon_aikido_coin.svg" alt="coin" />
      <small className="number-font">{new Intl.NumberFormat().format(points) || 0}</small>
    </div>
  );
};

export default DPCoin;
