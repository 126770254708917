import * as Sentry from '@sentry/react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Map from './pages/Map';
import Bonus from './pages/Bonus';
import Camera from './pages/Camera';
import Friends from './pages/Friends';
import Header from './components/Header';
import Profile from './pages/Profile.jsx';
import Leaderboard from './pages/Leaderboard';
import SnapMatch from './pages/SnapMatch.jsx';
import BottomNavBar from './components/BottomNavBar';
import ScrollToTop from './components/ScrollToTop.jsx';
import SnapMatchIntro from './pages/SnapMatchIntro.jsx';
import { fetchUserData } from './utils/user';
import { fetchConfig } from './utils/config.js';
import { setUserData } from './redux/slices/authSlice';
import { setConfig } from './redux/slices/configSlice.js';
import { incrementEnergy } from './redux/slices/snapMatchSlice';

import '../axios.config.js';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
});

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [direction, setDirection] = useState('ltr');

  const { _, isLoading: configLoading } = useQuery('config', fetchConfig, {
    onSuccess: (data) => {
      dispatch(setConfig(data));
    },
    staleTime: 1000 * 60 * 5,
  });

  const handleLanguageChange = () => {
    const language = localStorage.getItem('i18nextLng');

    console.log('lang change', language);
    if (language === 'fa' || language === 'ar') {
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
  };

  useEffect(() => {
    handleLanguageChange();

    window.addEventListener('storage', handleLanguageChange);

    window.addEventListener('languageChange', handleLanguageChange);

    return () => {
      window.removeEventListener('storage', handleLanguageChange);
      window.removeEventListener('languageChange', handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.unregister());
      });
    }
  }, []);

  useEffect(() => {
    window.Telegram.WebApp.ready();
    window.Telegram.WebApp.expand();
    window.Telegram.WebApp.enableClosingConfirmation();

    const user = window.Telegram.WebApp.initDataUnsafe.user;

    dispatch(setUserData({ tg: { id: user?.id, username: user?.username } }));

    fetchUserData(dispatch, user?.id).then(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      const splashScreen = document.querySelector('.splash-screen');
      if (splashScreen) {
        setTimeout(() => {
          splashScreen.style.opacity = 0;
          setTimeout(() => {
            splashScreen.style.display = 'none';
          }, 500);
        }, 2000);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    const energyInterval = setInterval(() => {
      dispatch(incrementEnergy());
    }, 10250);

    return () => clearInterval(energyInterval);
  }, [dispatch]);

  if (isLoading || configLoading) {
    return null;
  }

  return (
    <TonConnectUIProvider
      manifestUrl="https://www.dashpoolai.com/tonconnect-manifest.json"
      theme={THEME.DARK}
    >
      {/* <div style={{ textAlign: direction === 'rtl' ? 'right' : 'left' }}> */}
      <div dir={direction}>
        <Router>
          <Header />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Navigate to="/bonus" />} />
            <Route path="/map" element={<Map />} />
            <Route path="/bonus" element={<Bonus />} />
            <Route path="/camera" element={<Camera />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/friends" element={<Friends />} />
            <Route path="/snapmatch" element={<SnapMatch />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/snapmatch-intro" element={<SnapMatchIntro />} />
          </Routes>
          <BottomNavBar />
          <ToastContainer autoClose={3000} hideProgressBar={true} />
        </Router>
      </div>
    </TonConnectUIProvider>
  );
}

export default App;
