import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  maintenance: false,
  highLoad: false,
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;
