import axios from 'axios';

import { setAuth, setUserData } from '../redux/slices/authSlice';

const API_URL = import.meta.env.VITE_API_URL;

export const fetchUserData = async (dispatch, tg_id) => {
  try {
    const response = await axios.get(API_URL + `/users/user/${tg_id}`);
    const userData = response.data;

    dispatch(setUserData(userData));
    dispatch(setAuth(true));
  } catch (error) {
    console.error('Error fetching user data:', error);
    // toast.error('Error fetching user data');
  }
};

export const registerUser = async (user) => {
  try {
    const response = await axios.post(`${API_URL}/users/user`, {
      tg_id: user.id,
      tg_username: user.username,
      tg_first_name: user.first_name,
      tg_last_name: user.last_name,
      is_bot: user.is_bot,
      language_code: user.language_code,
      is_premium: user.is_premium,
      added_to_attachment_menu: user.added_to_attachment_menu,
      allows_write_to_pm: user.allows_write_to_pm,
      photo_url: user.photo_url,
    });

    return response.data;
  } catch (error) {
    console.error('Error Registering:', error);
    throw error;
  }
};
