import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import DPCoin from '../components/DPCoin';
import SlopeBox from '../components/SlopeBox';
import Walkthrough from '../components/Walkthrough';
import LoadingOverlay from '../components/LoadingOverlay';
import SlopeProgressBar from '../components/SlopeProgressBar';
import { setUserData } from '../redux/slices/authSlice';
import { setEnergy } from '../redux/slices/snapMatchSlice';

import '../assets/styles/SnapMatch.css';

const API_URL = import.meta.env.VITE_API_URL;
const MEDIA_URL = import.meta.env.VITE_MEDIA_URL;

const startSnapMatch = async ({ queryKey }) => {
  const [_, userId] = queryKey;
  const response = await axios.post(`${API_URL}/snapmatch/start/${userId}`);
  return response.data;
};

const fetchEnergy = async ({ queryKey }) => {
  const [_, userId] = queryKey;
  const response = await axios.get(`${API_URL}/snapmatch/energy/${userId}`);
  return response.data;
};

const completeSnapMatch = async ({ incidentId, rating, userId }) => {
  const response = await axios.post(`${API_URL}/snapmatch/complete`, {
    incidentId,
    rating,
    userId,
  });
  return response.data;
};

const SnapMatch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth?._id);
  const energy = useSelector((state) => state.snapMatch.energy);
  const [rating, setRating] = useState(0);
  const [rewardPoints, setRewardPoints] = useState(null);
  const [averageRating, setAverageRating] = useState(null);
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const [showRewardOverlay, setShowRewardOverlay] = useState(false);
  const [showEnergyOverlay, setShowEnergyOverlay] = useState(false);

  const [isImageLoading, setIsImageLoading] = useState(true);

  const handleImageLoad = () => {
    setIsImageLoading(false);
  };

  const { data: energyData } = useQuery(['energy', userId], fetchEnergy, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data?.energy) {
        dispatch(setEnergy(data.energy));
      }
    },
  });

  const {
    data: snapMatchData,
    isLoading,
    isError,
    error,
    refetch: fetchNextSnapMatch,
  } = useQuery(['snapMatch', userId], startSnapMatch, {
    refetchOnWindowFocus: false,
    enabled: true,
    onError: (error) => {
      if (error.response?.data?.insufficientEnergy) {
        setShowEnergyOverlay(true);
      }
    },
    onSuccess: (data) => {
      window.scrollTo(0, 0);

      if (data?.energy) {
        dispatch(setEnergy(data.energy));
      }
      setRating(0);
    },
    retry: false,
  });

  const mutation = useMutation(completeSnapMatch, {
    onSuccess: (data) => {
      setRewardPoints(data.rewardPoints);
      setAverageRating(data.averageRating);
      dispatch(setUserData({ points: data.userPoints }));
      setShowRewardOverlay(true);

      fetchNextSnapMatch();

      setTimeout(() => {
        setShowRewardOverlay(false);
      }, 3000);
    },
    onError: (error) => {
      toast.error(error.response?.data?.msg || 'Error completing Snap Match');
    },
  });

  const handleStarClick = (value) => {
    setRating(value);
    mutation.mutate({ incidentId: snapMatchData?.incident._id, rating: value, userId });
  };

  const joyrideSnapMatchSteps = [
    {
      target: '.snapmatch-image',
      route: '/snapmatch',
      placement: 'bottom',
      title: t('Take a look at the image'),
      content: <>{t('Detect the number of elements in the image')}</>,
      disableBeacon: true,
      // spotlightClicks: true,
      offset: 100,
    },
    {
      target: '.detected-categories',
      route: '/snapmatch',
      placement: 'bottom',
      title: t('Evaluate the accuracy of the detected elements'),
      content: (
        <>
          {t(
            'Check whether the AI has detected the right number of each elements present in the image.'
          )}
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: '.rating-stars',
      route: '/snapmatch',
      placement: 'bottom',
      title: t('Rate the accuracy'),
      content: <>{t('Rate the accuracy of the AI detection on this image.')}</>,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: '#energy-display',
      route: '/snapmatch',
      placement: 'bottom',
      title: t('Energy is Key!'),
      content: (
        <>
          {t('Each SnapMatch uses 50 Energy.')}
          <br />
          <br />
          {t(
            'No worries, your energy regenerates over time, so you can always come back for more rewards!'
          )}
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
      scrollToTarget: true,
    },
    {
      target: '#navbar-button-friends',
      route: '/snapmatch',
      placement: 'bottom',
      title: t('Bring Friends!'),
      content: (
        <>
          {t(
            'Add friends to build your team and increase everyone’s rewards—working together means bigger gains for all!'
          )}
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
    },
  ];

  useEffect(() => {
    const snapmatchWalkthrough = localStorage.getItem('snapmatchWalkthrough') === null;
    if (snapmatchWalkthrough) {
      localStorage.setItem('snapmatchWalkthrough', 'false');
      setShowWalkthrough(true);
    }
  }, []);

  return (
    <div className="snapmatch-container">
      {(isLoading || isImageLoading) && <LoadingOverlay />}

      {showWalkthrough && !isImageLoading && (
        <Walkthrough key="snapmatch" steps={joyrideSnapMatchSteps} place="snapmatch" />
      )}

      {showRewardOverlay && (
        <div className="reward-overlay">
          {rewardPoints === 0 ? (
            <p className="alert">
              {t('You earned 0 points.')}
              <br />
              {t(
                'Take a closer look at the image to ensure accurate ratings and maximize your rewards.'
              )}
            </p>
          ) : (
            <h1>{t('Congrats!')}</h1>
          )}
          <div style={{ marginBottom: '1rem' }}>
            <DPCoin points={rewardPoints} />
          </div>
          <SlopeBox>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <p>{t('Average rating by Aikido community')}</p>
              <strong className="text-accent-primary number-font">
                {' '}
                {averageRating?.toFixed(2) || 5}
              </strong>
            </div>
          </SlopeBox>
        </div>
      )}

      <div className="snapmatch-body">
        {isError && !showEnergyOverlay ? (
          <div>
            {t('Error')}: {error?.message}
          </div>
        ) : showEnergyOverlay ? (
          <>
            <div className="energy-message">
              <img src="/icon_electric_primary.svg" alt={t('low energy')} />
              <h1 className="color-accent-secondary">{t('Ooops...')}</h1>
              <p>
                {t('Not enough Snapping Energy')}
                <br />
                <br />
                {t('Please wait for your energy to regenerate to at least {{energy}}.', {
                  energy: 50,
                })}
              </p>
              <div className="energy-display" style={{ width: '100%' }}>
                <p style={{ marginBottom: '.5rem' }}>
                  {t('Energy')}: {energy}/{energyData?.maxEnergy}
                </p>
                <SlopeProgressBar progress={(energy / energyData?.maxEnergy) * 100} width="100%" />
              </div>
            </div>
          </>
        ) : (
          <>
            <img
              src={`${MEDIA_URL}/i/b/${snapMatchData?.incident?.image}` || '/raccoon.webp'}
              alt={t('snapmatch image')}
              className="snapmatch-image"
              onLoad={handleImageLoad}
            />
            <h4>{t('Rate the accuracy of the numbers')}</h4>
            <div className="detected-categories">
              {snapMatchData?.incident?.detection?.valid_categories_detected &&
                Object.entries(snapMatchData.incident.detection.valid_categories_detected).map(
                  ([category, count]) => (
                    <div key={category} className="category-box">
                      <SlopeBox>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                          }}
                        >
                          <p>{category}</p>
                          <p className="number-font">{count}</p>
                        </div>
                      </SlopeBox>
                    </div>
                  )
                )}
            </div>
            <div className="rating-stars">
              {[1, 2, 3, 4, 5].map((value) => (
                <span
                  key={value}
                  className={`star ${rating >= value ? 'selected' : ''}`}
                  onClick={() => handleStarClick(value)}
                >
                  <img src={`/${rating >= value ? 'icon_star_fill.svg' : 'icon_star_empty.svg'}`} />
                </span>
              ))}
            </div>
            <div id="energy-display" className="energy-display">
              <p>
                {t('Energy')}: {energy}/{energyData?.maxEnergy}
              </p>
              <SlopeProgressBar progress={(energy / energyData?.maxEnergy) * 100} width="100%" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SnapMatch;
