import { useTranslation } from 'react-i18next';

import SlopeBox from './SlopeBox';

const PointsSection = ({ points, distanceDriven }) => {
  const { t } = useTranslation();

  return (
    <div className="points-section">
      <SlopeBox>
        <div className="points">
          <span className="points-value number-font">
            {new Intl.NumberFormat().format(points) || 0}
          </span>
          <div className="points-label">
            <img src="/icon_aikido_coin.svg" alt={t('coin')} />
            <span>{t('Points')}</span>
          </div>
        </div>
      </SlopeBox>
      <SlopeBox>
        <div className="points">
          <span className="points-value number-font">
            {new Intl.NumberFormat().format(distanceDriven) || 0}
          </span>
          <div className="points-label">
            <img src="/icon_road_primary.svg" alt={t('coin')} />
            <span>{t('KM Driven')}</span>
          </div>
        </div>
      </SlopeBox>
    </div>
  );
};

export default PointsSection;
