import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import configReducer from './configSlice';
import snapMatchReducer from './snapMatchSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  snapMatch: snapMatchReducer,
});

export default rootReducer;
