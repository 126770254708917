import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IoLockClosedOutline } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import DPCoin from './DPCoin';
import { setDevMode } from '../redux/slices/authSlice';

import '../assets/styles/Header.css';
import { useQuery } from 'react-query';
import { setEnergy } from '../redux/slices/snapMatchSlice';
import SlopeProgressBar from './SlopeProgressBar';
import SlopeButton from './SlopeButton';
import { CgLock } from 'react-icons/cg';

const API_URL = import.meta.env.VITE_API_URL;

const fetchEnergy = async ({ queryKey }) => {
  const [_, userId] = queryKey;
  const response = await axios.get(`${API_URL}/snapmatch/energy/${userId}`);
  return response.data;
};

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.auth?._id);
  const energy = useSelector((state) => state.snapMatch.energy);
  const [clickCount, setClickCount] = useState(0);
  const { id, points, first_name, devMode, level } = useSelector((state) => ({
    id: state.auth?.tg?.id,
    points: state.auth?.points,
    devMode: state.auth?.devMode,
    first_name: state.auth?.tg?.first_name,
    level: state.auth?.level,
  }));

  const handleDevMode = () => {
    const newClickCount = clickCount + 1;
    setClickCount(newClickCount);

    if (newClickCount >= 12) {
      dispatch(setDevMode(true));
      setClickCount(0);

      toast.success('Developer mode activated.');
    }
  };

  const { data: energyData } = useQuery(['energy', userId], fetchEnergy, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data?.energy) {
        dispatch(setEnergy(data.energy));
      }
    },
  });

  const isMap = location.pathname === '/map';
  const isBonus = location.pathname === '/bonus';
  const isCamera = location.pathname === '/camera';
  const isProfile = location.pathname === '/profile';

  return (
    <div className={`header ${isMap || isBonus || isCamera ? 'header-bg' : ''}`}>
      <div className="header-content">
        <div
          style={{ display: 'flex', alignContent: 'center', gap: '1rem', justifyContent: 'center' }}
        >
          {id && (
            <Link to="/profile" className="profile-link">
              <div className="profile">
                {isProfile && (
                  <div onClick={() => navigate(-1)}>
                    <img src="/icon_back_square.svg" alt="profile" />
                  </div>
                )}
                {!isProfile && (
                  <>
                    <img src="/icon_user.svg" alt="profile" />
                    {first_name?.slice(0, 25)}
                    {first_name?.length > 25 ? '...' : ''}
                    <div id="level-icon" className="level-icon">
                      <span>{level?.level || 1}</span>
                    </div>
                  </>
                )}
              </div>
            </Link>
          )}
        </div>
        {id && (
          <div onClick={handleDevMode}>
            <DPCoin points={points} />
          </div>
        )}
      </div>

      {(isMap || isBonus) && (
        <div className="header-snapmatch">
          <Link
            id="snapmatch-button"
            to={energy > 50 ? '/snapmatch-intro' : '#'}
            onClick={(e) => energy <= 50 && e.preventDefault()}
            style={{ textDecoration: 'none' }}
          >
            <SlopeButton variant="primary" size="sm" disabled={energy <= 50} fullWidth>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '.5rem',
                }}
              >
                {energy <= 50 && <CgLock size={24} />}
                <p>SnapMatch</p>
              </div>
            </SlopeButton>
          </Link>

          <div className="header-snapmatch-stats">
            <p>
              {t('Energy')}: {energy}/{energyData?.maxEnergy}
            </p>

            <SlopeProgressBar progress={(energy / energyData?.maxEnergy) * 100} width="100%" />
          </div>
        </div>
      )}

      {!id && <small className="error">Not Connected</small>}
    </div>
  );
};

export default Header;
