import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  energy: 0,
};

export const snapMatchSlice = createSlice({
  name: 'snapMatch',
  initialState,
  reducers: {
    setEnergy: (state, action) => {
      state.energy = action.payload;
    },
    incrementEnergy: (state) => {
      if (state.energy < 1000) {
        state.energy += 1;
      }
    },
  },
});

export const { setEnergy, incrementEnergy } = snapMatchSlice.actions;

export default snapMatchSlice.reducer;
