import '../assets/styles/SlopeProgressBar.css';

const SlopeProgressBar = ({
  progress = 0,
  highlight = false,
  color = 'color-accent-primary',
  width = '100px',
}) => {
  return (
    <div className={`slope-progress-bar ${highlight ? 'highlight' : ''}`} style={{ width }}>
      <div
        className="slope-progress"
        style={{ width: `${progress}%`, backgroundColor: `var(--${color})` }}
      ></div>
    </div>
  );
};

export default SlopeProgressBar;
