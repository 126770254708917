import axios from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { FaUser } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import DPCoin from '../components/DPCoin';
import SlopeCard from '../components/SlopeCard';
import LoadingOverlay from '../components/LoadingOverlay';

import 'swiper/css';
import 'swiper/css/navigation';
import '../assets/styles/Leaderboard.css';

const API_URL = import.meta.env.VITE_API_URL;

const leagueRewards = {
  platinum: { rank: 3, points: 100, icon: '/icon_rank_class_first.svg' },
  gold: { rank: 10, points: 50, icon: '/icon_rank_class_second.svg' },
  silver: { rank: 50, points: 25, icon: '/icon_rank_class_third.svg' },
  bronze: { rank: 100, points: 10, icon: '/icon_rank_class_forth.svg' },
};

const getLeagueByRank = (rank) => {
  if (rank <= leagueRewards.platinum.rank) return 'platinum';
  if (rank <= leagueRewards.gold.rank) return 'gold';
  if (rank <= leagueRewards.silver.rank) return 'silver';
  return 'bronze';
};

const LeaderboardItem = ({ sortBy, item, index, me }) => {
  const { t } = useTranslation();
  const league = getLeagueByRank(me ? item.rank : index + 1);
  const leagueIcon = leagueRewards[league].icon;

  const statContentMap = {
    distance: `${item.weeklyDistanceDriven?.toFixed(2) || 0} km`,
    distanceDriven: `${item.distanceDriven?.toFixed(2) || 0} km`,
    points: (
      <>
        <img src="/icon_aikido_coin.svg" alt={t('Coin')} />
        <span>{item.weeklyPoints}</span>
      </>
    ),
    allTimePoints: (
      <>
        <img src="/icon_aikido_coin.svg" alt={t('Coin')} />
        <span>{item.points}</span>
      </>
    ),
    invites: (
      <div>
        <span className="text-accent-primary">{t('Invites')}:</span>{' '}
        <span>{new Intl.NumberFormat().format(item.weeklyInvites || 0)}</span>
      </div>
    ),
  };

  const getStatContent = () => {
    return sortBy === 'distance' || sortBy === 'distanceDriven' ? (
      <>
        <img src="/icon_road_primary.svg" alt={t('Road')} />
        <span>{statContentMap[sortBy]}</span>
      </>
    ) : (
      statContentMap[sortBy] || <span />
    );
  };

  return (
    <SlopeCard
      rightContent={
        <div className="leaderboard-item">
          <div className="leaderboard-item-league">
            <img src={leagueIcon || '/icon_bronze.webp'} alt={league} className="league-icon" />
          </div>
          <div className="leaderboard-item-user">
            <div className="leaderboard-item-user-profile">
              <FaUser size={15} className="text-accent-primary" />
              {item?.tg?.first_name
                ?.replace(/\u3164/g, '')
                ?.trim()
                ?.slice(0, 15) ||
                item?.tg?.username?.replace(/\u3164/g, '')?.trim() ||
                'N/A'}
            </div>
            <div className={sortBy === 'invites' ? '' : 'leaderboard-item-user-stats'}>
              {getStatContent()}
            </div>
          </div>
        </div>
      }
      leftContent={
        <div className="leaderboard-item-rank">
          <h2>{me ? item.rank : index + 1}</h2>
          <p>Place</p>
        </div>
      }
      reverse
      slopeWidth="35%"
      highlight={me}
    />
  );
};

const Leaderboard = () => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const { maintenance, highLoad } = useSelector((state) => state.config);
  const tg_id = auth?.tg?.id;

  const sortByOptions = ['distance', 'points', 'invites', 'distanceDriven', 'allTimePoints'];
  const [swiperIndex, setSwiperIndex] = useState(0);

  const fetchAllLeaderboards = async () => {
    const responses = await Promise.all([
      axios.get(`${API_URL}/leaderboard/week/distance`),
      axios.get(`${API_URL}/leaderboard/week/points`),
      axios.get(`${API_URL}/leaderboard/week/invites`),
      axios.get(`${API_URL}/users/leaderboard/${tg_id}?sortBy=distanceDriven`),
      axios.get(`${API_URL}/users/leaderboard/${tg_id}?sortBy=points`),
    ]);

    return {
      weeklyTopDrivers: responses[0].data,
      weeklyTopPoints: responses[1].data,
      weeklyTopInvites: responses[2].data,
      allTimeTopDrivers: responses[3].data,
      allTimeTopPoints: responses[4].data,
    };
  };

  const { data, isLoading, isError, error } = useQuery(
    ['allLeaderboards', tg_id],
    fetchAllLeaderboards,
    {
      staleTime: 1000 * 60 * 5,
      enabled: !maintenance && !highLoad,
    }
  );

  const getDataBySwiperIndex = (index) => {
    switch (index) {
      case 0:
        return data?.weeklyTopDrivers;
      case 1:
        return data?.weeklyTopPoints;
      case 2:
        return data?.weeklyTopInvites;
      case 3:
        return data?.allTimeTopDrivers;
      case 4:
        return data?.allTimeTopPoints;
      default:
        return [];
    }
  };

  const userReward = getDataBySwiperIndex(swiperIndex)?.me?.reward;

  if (maintenance) {
    return (
      <div className="leaderboard-container">
        <div className="leaderboard-header">
          <h1>{t('Leaderboard')}</h1>
        </div>

        <div className="leaderboard-body">
          <div className="announcement-banner">
            <img src="/maintenance.webp" alt={t('maintenance')} />
            <h1>{t("We'll Be Back Soon!")}</h1>
            <p>
              {t('The leaderboard is currently down for maintenance. Please check back shortly.')}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (highLoad) {
    return (
      <div className="leaderboard-container">
        <div className="leaderboard-header">
          <h1>{t('Leaderboard')}</h1>
        </div>

        <div className="leaderboard-body">
          <div className="announcement-banner">
            <img src="/icon_clock.svg" alt={t('high load')} />
            <h1>{t("We're Onboarding New Users!")}</h1>
            <p>{t('The leaderboard will be available soon after the new users are onboarded.')}</p>
          </div>
        </div>
      </div>
    );
  }

  const getLeaderboardTitle = (index) => {
    switch (index) {
      case 0:
        return t('Weekly Top Drivers');
      case 1:
        return t('Weekly Top Points');
      case 2:
        return t('Weekly Top Invites');
      case 3:
        return t('All-Time Top Drivers');
      case 4:
        return t('All-Time Top Points');
      default:
        return '';
    }
  };

  return (
    <div className="leaderboard-container">
      {isLoading && <LoadingOverlay />}

      <div className="leaderboard-header">
        <h1>{t('Leaderboard')}</h1>
      </div>

      <Swiper
        spaceBetween={50}
        slidesPerView={1.5}
        centeredSlides={true}
        onSlideChange={(swiper) => setSwiperIndex(swiper.activeIndex)}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[Navigation]}
      >
        <SwiperSlide>
          <h1>
            {t('Weekly Top')} <br /> {t('Drivers')}
          </h1>
        </SwiperSlide>
        <SwiperSlide>
          <h1>
            {t('Weekly Top')} <br /> {t('Points')}
          </h1>
        </SwiperSlide>
        <SwiperSlide>
          <h1>
            {t('Weekly Top')} <br /> {t('Invites')}
          </h1>
        </SwiperSlide>
        <SwiperSlide>
          <h1>
            {t('All-Time Top')} <br /> {t('Drivers')}
          </h1>
        </SwiperSlide>
        <SwiperSlide>
          <h1>
            {t('All-Time Top')} <br /> {t('Points')}
          </h1>
        </SwiperSlide>

        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </Swiper>

      <div className="leaderboard-body">
        {swiperIndex < 3 && (
          <div style={{ padding: '0 1rem' }}>
            <div className="rank-rewards-container">
              {Object.entries(leagueRewards).map(([league, { rank, points }]) => (
                <div key={league} className="top-left-box rank-reward-box">
                  <span>{t('Top {{rank}}', { rank })}</span>
                  <DPCoin points={points} />
                </div>
              ))}
            </div>
            {userReward > 0 ? (
              <div className="top-left-box" style={{ padding: '2rem 1rem', marginTop: '2rem' }}>
                <div className="reward-banner-context">
                  <h2
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                    }}
                  >
                    {t('Rewards This Week:')}
                    <DPCoin points={userReward} />
                  </h2>
                </div>
              </div>
            ) : (
              <div className="reward-banner top-left-box">
                <div className="reward-banner-context">
                  <p>{t('Reach the')}</p>
                  <h1>{t('Top 100')}</h1>
                  <span>{t('To Earn Rewards!')}</span>
                </div>

                <img src="/raccoon.webp" alt={t('medal')} className="reward-banner-medal" />
              </div>
            )}
          </div>
        )}

        <p className="leaderboard-subject">{t('You Are Here')}</p>

        {getDataBySwiperIndex(swiperIndex)?.me && (
          <LeaderboardItem
            sortBy={sortByOptions[swiperIndex]}
            item={getDataBySwiperIndex(swiperIndex).me}
            index={1000}
            me
          />
        )}

        <p className="leaderboard-subject">{getLeaderboardTitle(swiperIndex)}</p>

        <div className="leaderboard">
          {getDataBySwiperIndex(swiperIndex)?.top?.length > 0 ? (
            getDataBySwiperIndex(swiperIndex).top.map((item, index) => (
              <LeaderboardItem
                key={item.tg.id}
                sortBy={sortByOptions[swiperIndex]}
                item={item}
                index={index}
              />
            ))
          ) : (
            <div className="no-incidents-message">{t('The leaderboard is being prepared.')}</div>
          )}
        </div>
        {isError && (
          <div className="error-message">
            {t('Error')}: {error.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default Leaderboard;
