import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Joyride, { STATUS } from 'react-joyride';
import React, { useEffect, useRef, useState } from 'react';

const Walkthrough = ({ steps, place }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const joyrideHelpers = useRef(null);
  const [run, setRun] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);
  const [snapmatches, setSnapmatches] = useState(0);
  const { devMode } = useSelector((state) => state.auth);

  useEffect(() => {
    setRun(true);
    setStepIndex(0);
  }, [place]);

  const handleRouting = (place) => {
    switch (place) {
      case 'bonus':
        navigate('/snapmatch-intro');
        break;
      case 'snapmatch-intro':
        navigate('/snapmatch');
        break;
      case 'snapmatch':
        navigate('/friends');
        break;
      default:
        break;
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status, action, type, step } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      handleRouting(place);
    } else if (type === 'step:after') {
      if (action === 'next') {
        const nextIndex = stepIndex + 1;
        if (nextIndex < steps.length) {
          setStepIndex(nextIndex);

          const targetElement = document.querySelector(steps[nextIndex].target);
          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            });
          }
        } else {
          setRun(false);
          handleRouting(place);
        }
      } else if (action === 'prev') {
        setStepIndex((prevIndex) => prevIndex - 1);
      } else if (action === 'close') {
        // const nextIndex = stepIndex + 1;

        // if (step.target === '.rating-stars') {
        //   setSnapmatches((prev) => {
        //     const newSnapmatches = prev + 1;
        //     if (newSnapmatches > 1) {
        //       if (nextIndex < steps.length) {
        //         setStepIndex(nextIndex);
        //       } else {
        //         setRun(false);
        //         handleRouting(place);
        //       }
        //     }
        //     return newSnapmatches;
        //   });
        // } else {
        //   if (nextIndex < steps.length) {
        //     setStepIndex(nextIndex);
        //   } else {
        setRun(false);
        handleRouting(place);
        //   }
        // }
      }
    }
  };

  // if (!devMode) return <></>;

  return (
    <>
      <Joyride
        steps={steps}
        run={run}
        stepIndex={stepIndex}
        continuous={true}
        callback={handleJoyrideCallback}
        getHelpers={(helpers) => {
          joyrideHelpers.current = helpers;
        }}
        disableScrolling={true}
        styles={{
          options: {
            zIndex: 10000,
          },
          tooltip: {
            borderRadius: '20px',
            padding: '20px',
            backgroundColor: '#fff',
            color: '#333',
            maxWidth: '340px',
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
          },
          buttonNext: {
            background:
              'linear-gradient(86.18deg, var(--color-accent-primary) 8.97%, #00caca 75.44%)',
            borderRadius: '0',
            color: 'var(--color-bg-main)',
            padding: '10px 20px',
            // fontSize: '16px',
          },
          buttonBack: {
            marginRight: '10px',
          },
          buttonSkip: {
            backgroundColor: 'transparent',
            color: '#666',
          },
          tooltipTitle: {
            marginTop: '10px',
          },
        }}
        locale={{
          back: t('Back'),
          close: t('Close'),
          last: t('Finish'),
          next: t('Next'),
          skip: t('Skip'),
        }}
        hideBackButton
      />
    </>
  );
};

export default Walkthrough;
