import Flag from 'react-world-flags';
import { useState } from 'react';
import { FaCheck } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';

const languages = [
  { code: 'en', label: 'English', flag: 'GB' },
  { code: 'fr', label: 'Français', flag: 'FR' },
  { code: 'fa', label: 'فارسی', flag: 'IR' },
  { code: 'ru', label: 'Русский', flag: 'RU' },
  { code: 'uz', label: 'Oʻzbekcha', flag: 'UZ' },
  { code: 'tr', label: 'Türkçe', flag: 'TR' },
  { code: 'uk', label: 'Українська', flag: 'UA' },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(
    languages.find((lang) => lang.code === i18n.language)
  );

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const selected = languages.find((lang) => lang.code === lng);
    setSelectedLang(selected);
    setIsOpen(false);

    const event = new Event('languageChange');
    window.dispatchEvent(event);
  };

  return (
    <div className="language-selector">
      {languages.map((lang) => (
        <div key={lang.code} className="language-option" onClick={() => changeLanguage(lang.code)}>
          <Flag code={lang.flag} width={24} />
          <span>{lang.label}</span>
          {selectedLang.code === lang.code && (
            <FaCheck size={25} style={{ marginLeft: 'auto' }} className="color-accent-primary" />
          )}
        </div>
      ))}
    </div>
  );
};

export default LanguageSelector;
