import { useTranslation } from 'react-i18next';

import '../assets/styles/Overlay.css';

const Overlay = ({ title, message, onRegister = false }) => {
  const { t } = useTranslation();

  return (
    <div className="overlay">
      <div className="overlay-content">
        <h1 className="overlay-title">{title}</h1>
        <div className="overlay-message">{message}</div>
        {onRegister && (
          <button className="overlay-button" onClick={onRegister}>
            {t('Register')}
          </button>
        )}
      </div>
    </div>
  );
};

export default Overlay;
