import { useEffect, useState } from 'react';

import '../assets/styles/LoadingOverlay.css';

const funFacts = [
  'The more you drive, the more points you earn in DashPool!',
  'Did you know? You can invite friends and earn extra rewards in DashPool!',
  'DashPool helps you track your driving habits for better efficiency!',
  'Compete on the DashPool leaderboard and see how you rank among your peers!',
  'Keep your driving streak alive to maximize your points in DashPool!',
  'DashPool processes real-time images to create high-resolution, dynamic maps!',
  'Earn rewards in DashPool by contributing quality, verified geographic data!',
  'DashPool’s community-driven platform rewards you for your contributions and participation!',
  'Join a global community of contributors and help build the most accurate, up-to-date maps!',
  'With DashPool, you can access maps that are constantly updated by a decentralized network of users!',
  'Your contributions to DashPool help urban planners and developers create better, smarter cities!',
  'DashPool uses advanced AI to process and verify geographic data in real-time!',
  'Stay on top of the leaderboard in DashPool by driving more and contributing valuable data!',
];

const LoadingOverlay = () => {
  // const [currentFact, setCurrentFact] = useState(
  //   funFacts[Math.floor(Math.random() * funFacts.length)]
  // );
  // const [fadeIn, setFadeIn] = useState(true);

  // useEffect(() => {
  //   const factInterval = setInterval(() => {
  //     setFadeIn(false);
  //     setTimeout(() => {
  //       setCurrentFact(() => {
  //         let newFact;
  //         do {
  //           newFact = funFacts[Math.floor(Math.random() * funFacts.length)];
  //         } while (newFact === currentFact);
  //         return newFact;
  //       });
  //       setFadeIn(true);
  //     }, 500);
  //   }, 3500);

  //   return () => clearInterval(factInterval);
  // }, [currentFact]);

  return (
    <div className="loading-overlay">
      <div className="logo-container">
        <img src="/logo_aikido.webp" alt="Aikido Logo" className="bouncing-logo" />
      </div>
      {/* <p className={`loading-text ${fadeIn ? 'fade-in' : 'fade-out'}`}>{currentFact}</p> */}
    </div>
  );
};

export default LoadingOverlay;
