import axios from 'axios';
import { toast } from 'react-toastify';

const API_URL = import.meta.env.VITE_API_URL;

axios.interceptors.request.use(
  function (config) {
    if (config.url.startsWith(API_URL)) {
      try {
        window.Telegram.WebApp.ready();
        const initData = window.Telegram.WebApp.initData;

        config.headers['X-Telegram-Init-Data'] = initData;
      } catch (error) {
        console.error('Error adding init data to headers:', error);
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        toast.error('Access Denied');
      } else if (
        error.response.status === 400 &&
        error.response.headers['X-Custom-Error'] === 'Telegram auth data missing from headers'
      ) {
        console.log('missing');
        toast.error('Telegram auth data missing from headers');
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
