import '../assets/styles/SlopeButton.css';

const SlopeButton = ({
  children,
  variant = 'primary',
  size = 'md',
  highlight = false,
  disabled = false,
  fullWidth = false,
  ...props
}) => {
  return (
    <button
      className={`slope-button ${variant} ${size} ${highlight ? 'highlight' : ''}`}
      disabled={disabled}
      {...props}
      style={{ width: fullWidth ? '100%' : 'auto' }}
    >
      {children}
    </button>
  );
};

export default SlopeButton;
