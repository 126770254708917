import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaChevronRight, FaChevronUp } from 'react-icons/fa';
import { TonConnectButton, useTonAddress } from '@tonconnect/ui-react';

import DPCoin from '../components/DPCoin';
import SlopeBox from '../components/SlopeBox';
import LoadingOverlay from '../components/LoadingOverlay';
import SlopeProgressBar from '../components/SlopeProgressBar';
import LanguageSelector from '../components/LanguageSelector';
import { LevelConfigurations } from '../utils/level';

import '../assets/styles/Profile.css';

const BUILD_HASH = import.meta.env.VITE_BUILD_HASH;

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tonAddress = useTonAddress();
  const user = useSelector((state) => state.auth);
  const [swiperIndex, setSwiperIndex] = useState(user.level?.level - 1 || 0);

  const shareInviteLink = () => {
    window.Telegram.WebApp.openTelegramLink(
      `https://t.me/share/url?url=t.me/DashPoolBot/?start=invite=${user?.tg?.id}`
    );
  };
  const startWalkThrough = () => {
    localStorage.removeItem('bonusWalkthrough');
    localStorage.removeItem('cameraWalkthrough');
    localStorage.removeItem('friendsWalkthrough');
    localStorage.removeItem('snapmatchWalkthrough');
    localStorage.removeItem('snapmatchIntroWalkthrough');

    navigate('/bonus');
  };

  if (!user || !user.points || !user.nextLevel || user.nextLevel.pointsNeeded === undefined) {
    return <LoadingOverlay />;
  }

  return (
    <div className="profile-container">
      <div className="profile-body">
        <div className="profile-avatar-section">
          <h1>{user.tg?.first_name || 'User'}</h1>
          <p>
            {t('Your Level')}: {user.level?.level || 1}
          </p>
        </div>

        <div className="level-progress">
          <div className="level-section">
            <div className="level-badge">
              <div className="level-icon">
                <span>{user.level?.level || 1}</span>
              </div>
              <span>{user.level?.name}</span>
            </div>
            <FaChevronRight size={20} className="chevron-icon" />
          </div>

          <SlopeProgressBar
            progress={(user.points / user.nextLevel.pointsNeeded) * 100}
            width="100%"
          />
          <p>
            {t('Level Progress')}: {user.points}/{user.nextLevel.pointsNeeded}
          </p>
        </div>

        <div className="profile-levels">
          <Swiper
            spaceBetween={25}
            slidesPerView={1.7}
            centeredSlides={true}
            initialSlide={swiperIndex}
            onSlideChange={(swiper) => setSwiperIndex(swiper.activeIndex)}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            modules={[Navigation]}
          >
            {LevelConfigurations.map((item) => (
              <SwiperSlide key={item.level}>
                <div className="level-belt-container" style={{ backgroundColor: item.color }}>
                  <div className="level-belt">
                    <h3>{item.name}</h3>

                    <DPCoin points={item.pointsNeeded} style={{ marginTop: '.5rem' }} />

                    <div
                      className="top-left-box level-belt-index"
                      style={{
                        backgroundColor: item.color,
                        color:
                          item.level === 1 || item.level === 2 || item.level === 20
                            ? 'var(--color-bg-main)'
                            : '',
                      }}
                    >
                      <p>
                        {t('Level')} {item.level}
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </Swiper>

          <div className="profile-levels-multipler">
            <p>{t('point multiplier').toUpperCase()}</p>
            <SlopeBox>{LevelConfigurations[swiperIndex].multiplier.toFixed(2)}x</SlopeBox>
          </div>
        </div>

        <div className="action-section">
          <div className="action-item">
            <img src="/icon_wallet.svg" alt="wallet" className="action-icon" />
            {tonAddress ? (
              <div className="action-item-wallet">
                <span>
                  {t('Connected Wallet')}: {tonAddress.slice(0, 4)}...{tonAddress.slice(-4)}
                </span>
              </div>
            ) : (
              <div className="action-item-wallet">
                <span>{t('Wallet')}</span>
                <TonConnectButton style={{ marginLeft: 'auto' }} />
              </div>
            )}
          </div>
          <div className="action-item" onClick={shareInviteLink}>
            <img src="/icon_friends_white.svg" alt="wallet" className="action-icon" />
            <span>{t('Invite Friends')}</span>
            <FaChevronUp size={20} className="chevron-icon" />
          </div>
          <div className="action-item" onClick={startWalkThrough}>
            <img src="/icon_target.svg" alt="wallet" className="action-icon" />
            <span>{t('Show Walkthrough')}</span>
          </div>
        </div>

        <div className="language-section">
          <h2>{t('Language')}</h2>
          <div className="language-options">
            <LanguageSelector />
          </div>
        </div>

        <div className="social-section">
          <h2>{t('Socials')}</h2>

          <div className="social-items">
            <a href="https://t.me/+-UzzeSSv415mMWU0" target="_blank" rel="noopener noreferrer">
              <img
                src="https://asset.brandfetch.io/id68S6e-Gp/id4intND9w.svg"
                alt={t('Telegram')}
              />
            </a>
            <a href="https://x.com/the_aikido" target="_blank" rel="noopener noreferrer">
              <img src="https://asset.brandfetch.io/idS5WhqBbM/id-opmlNbH.svg" alt={t('Twitter')} />
            </a>
            <a
              href="https://www.youtube.com/@the_aikido?sub_confirmation=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://cdn.brandfetch.io/idVfYwcuQz/theme/dark/symbol.svg?k=bfHSJFAPEG"
                alt={t('YouTube')}
              />
            </a>
          </div>
        </div>
      </div>

      <p className="build-hash">Build: {BUILD_HASH}</p>
    </div>
  );
};

export default Profile;
