import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import * as nominatim from 'nominatim-browser';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosInformationCircleOutline,
  IoIosRefresh,
} from 'react-icons/io';

import IncidentCard from '../components/IncidentCard';
import LoadingSpinner from '../components/LoadingSpinner';

import 'mapbox-gl/dist/mapbox-gl.css';
import '../assets/styles/Map.css';

mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_TOKEN;

const INCIDENTS_RADIUS = 5000;
const API_URL = import.meta.env.VITE_API_URL;

const Map = () => {
  const { t } = useTranslation();
  const lastFetchTimeRef = useRef(0);
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const [coordinates, setCoordinates] = useState([0, 0]);
  const [addressDetails, setAddressDetails] = useState({});
  const [recentIncidents, setRecentIncidents] = useState([]);
  const [refreshCoolDown, setRefreshCoolDown] = useState(false);
  const [expandedIncident, setExpandedIncident] = useState(null);

  const fetchIncidents = async () => {
    try {
      const response = await axios.get(`${API_URL}/incidents/recent`);
      const incidents = response.data.incidents;

      const initialAddressDetails = incidents.reduce((acc, incident) => {
        acc[incident._id] = {
          city: 'Locating the event',
          province: 'Locating the event',
          road: 'Locating the event',
          suburb: 'Locating the event',
          postcode: 'Locating the event',
          country: 'Locating the event',
        };
        return acc;
      }, {});

      setAddressDetails(initialAddressDetails);
      setRecentIncidents(incidents);

      setRefreshCoolDown(true);
      setLoading(false);
      setRefresh(false);
      setTimeout(() => {
        setRefreshCoolDown(false);
      }, 20000);

      for (const incident of incidents) {
        const { coordinates } = incident.location;
        try {
          const response = await nominatim.reverseGeocode({
            lat: coordinates[1],
            lon: coordinates[0],
            addressdetails: true,
          });
          const address = response.address;
          if (address.city === 'بشیکتاش') {
            address.city = 'Beşiktaş';
          }

          setAddressDetails((prevDetails) => ({
            ...prevDetails,
            [incident._id]: {
              city: address.city || address.town || 'Unknown Location',
              province: address.state || address.province || 'Unknown State/Province',
              road: address.road || 'Unknown Road',
              suburb: address.suburb || 'Unknown Suburb',
              postcode: address.postcode || 'Unknown Postcode',
              country: address.country || 'Unknown Country',
            },
          }));

          await new Promise((resolve) => setTimeout(resolve, 500));
        } catch (error) {
          console.error('Error fetching address details:', error);
          setAddressDetails((prevDetails) => ({
            ...prevDetails,
            [incident._id]: {
              city: 'Unknown Location',
              province: 'Unknown State/Province',
              road: 'Unknown Road',
              suburb: 'Unknown Suburb',
              postcode: 'Unknown Postcode',
              country: 'Unknown Country',
            },
          }));
        }
      }
    } catch (error) {
      console.error('Error fetching incidents:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIncidents();
  }, []);

  const FETCH_INTERVAL = 20000;

  useEffect(() => {
    if (mapContainerRef.current) {
      const initializeMap = ({ setMap, mapContainerRef }) => {
        const map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/dark-v10',
          center: [coordinates[0], coordinates[1]],
          zoom: 14,
        });

        const geolocateControl = new mapboxgl.GeolocateControl({
          showUserHeading: true,
          showUserLocation: true,
          trackUserLocation: true,
          showAccuracyCircle: true,
          fitBoundsOptions: {
            zoom: 14,
            animate: false,
          },
          positionOptions: {
            enableHighAccuracy: true,
          },
        });

        map.addControl(geolocateControl);

        map.on('load', () => {
          geolocateControl.trigger();

          setMap(map);
        });
      };

      if (!map) {
        setLoading(true);
        initializeMap({ setMap, mapContainerRef });
        setLoading(false);
      }
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      getCurrentLocation();
      const currentPositionInterval = setInterval(() => getCurrentLocation(), 3000);

      return () => {
        clearInterval(currentPositionInterval);
      };
    }
  }, [map]);

  useEffect(() => {
    const currentTime = Date.now();
    if (
      coordinates[0] &&
      coordinates[1] &&
      currentTime - lastFetchTimeRef.current > FETCH_INTERVAL
    ) {
      fetchNearbyIncidents(coordinates[0], coordinates[1]);
      lastFetchTimeRef.current = currentTime;
    }
  }, [coordinates]);

  useEffect(() => {
    if (map && incidents.length > 0) {
      incidents.forEach((incident) => {
        const el = document.createElement('div');
        el.className = 'incident-marker';

        const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(`
          <h3>Incident</h3>
          <p>${incident.response.processed_text}</p>
          <p><strong class='color-accent-primary'> ${new Date(incident.createdAt).toLocaleString(
            'en-UK'
          )}</strong></p>
        `);

        new mapboxgl.Marker(el)
          .setLngLat([incident.location.coordinates[0], incident.location.coordinates[1]])
          .setPopup(popup)
          .addTo(map);
      });
    }
  }, [map, incidents]);

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const current_latitude = position.coords.latitude;
        const current_longitude = position.coords.longitude;
        const newCoordinates = [current_longitude, current_latitude];
        setCoordinates(newCoordinates);
      },
      (error) => {
        console.error('Error getting current location: ', error);
      },
      { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
    );
  };

  const fetchNearbyIncidents = async (longitude, latitude) => {
    try {
      const response = await axios.post(
        `${API_URL}/incidents/nearby`,
        {
          longitude: longitude.toString(),
          latitude: latitude.toString(),
          maxDistance: INCIDENTS_RADIUS,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setIncidents(response.data);
    } catch (error) {
      console.error('Error fetching nearby incidents:', error);
    }
  };

  const handleCardClick = (incident) => {
    setExpandedIncident(expandedIncident === incident._id ? null : incident._id);
  };

  const togglePanel = () => {
    if (!panelOpen) {
      setPanelOpen(true);
      const panel = document.querySelector('.sliding-panel');
      panel.classList.add('open-initial');

      panel.getBoundingClientRect();

      setTimeout(() => {
        panel.classList.remove('open-initial');
        panel.classList.add('open-final');
      }, 100);
    } else {
      setPanelOpen(false);
      const panel = document.querySelector('.sliding-panel');
      panel.classList.remove('open-final');
    }
  };

  const showOnMap = (incident) => {
    setPanelOpen(false);

    const [longitude, latitude] = incident.location.coordinates;

    const existingMarker = document.querySelector(`.incident-marker[data-id='${incident._id}']`);
    if (existingMarker) {
      map.flyTo({ center: [longitude, latitude], zoom: 14, duration: 1000 });
      return;
    }

    const el = document.createElement('div');
    el.className = 'incident-marker';
    el.setAttribute('data-id', incident._id);

    const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(`
      <h3>Incident</h3>
      <p>${incident.response.processed_text}</p>
      <p><strong>Date:</strong><span class='color-accent-primary'> ${new Date(
        incident.createdAt
      ).toLocaleString('en-UK')}</span></p>
    `);

    new mapboxgl.Marker(el).setLngLat([longitude, latitude]).setPopup(popup).addTo(map);

    map.flyTo({ center: [longitude, latitude], zoom: 14, duration: 2000 });
  };

  const handleRefreshRecentIncidents = async () => {
    try {
      setRefresh(true);

      await fetchIncidents();
    } catch (error) {
      console.error('Error refreshing incidents:', error);
      setRefresh(false);

      setTimeout(() => {
        setRefreshCoolDown(false);
      }, 20000);
    }
  };

  return (
    <div className="map-container">
      {loading && <LoadingSpinner />}
      <p className="card">
        <IoIosInformationCircleOutline size={18} /> {t('Showing incidents within')}{' '}
        {INCIDENTS_RADIUS / 1000} {t('km')}
      </p>
      <div ref={mapContainerRef} className="map" />
      <div
        onClick={!panelOpen ? togglePanel : () => {}}
        className={`panel-toggle ${panelOpen ? 'open' : ''}`}
      >
        <div onClick={togglePanel}>
          <span className="arrow">
            {panelOpen ? <IoIosArrowDown size={20} /> : <IoIosArrowUp size={20} />}
          </span>
          <p className="text-light">{t('Recent Incidents in the World')}</p>
        </div>

        {panelOpen && (
          <IoIosRefresh
            size={20}
            className={`${refresh ? 'rotating-item' : ''} ${refreshCoolDown ? 'text-muted' : ''}`}
            onClick={!refresh && !refreshCoolDown ? handleRefreshRecentIncidents : null}
            aria-disabled={refresh}
          />
        )}
      </div>
      <div className={`sliding-panel ${panelOpen ? 'open' : ''}`}>
        <div className="scroll">
          {recentIncidents.length > 0 ? (
            recentIncidents.map((incident) => (
              <IncidentCard
                key={incident._id}
                incident={incident}
                expandedIncident={expandedIncident}
                handleCardClick={handleCardClick}
                addressDetails={addressDetails[incident._id]}
                showOnMap={showOnMap}
              />
            ))
          ) : (
            <div className="no-incidents-message">{t('No incidents detected recently')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Map;
