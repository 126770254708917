import '../assets/styles/SlopeCard.css';

const SlopeCard = ({
  reverse = false,
  highlight = false,
  slopeWidth = '20%',
  leftContent,
  rightContent,
  ...props
}) => {
  return (
    <div
      className={`slope-card ${highlight ? 'highlighted' : ''} ${reverse ? 'reversed' : ''}`}
      style={{ '--slope-width': slopeWidth }}
      {...props}
    >
      <div className={`slope-card-content ${reverse ? 'right' : 'left'}`}>
        {reverse ? rightContent : leftContent}
      </div>
      <div className={`slope-card-content ${reverse ? 'left' : 'right'}`}>
        {reverse ? leftContent : rightContent}
      </div>
    </div>
  );
};

export default SlopeCard;
