import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGeolocation } from '@uidotdev/usehooks';
import { useDispatch, useSelector } from 'react-redux';

import { setLocation, setLocationError } from '../redux/slices/authSlice';

const ALLOWED_PATHS = ['/map', '/camera'];

export const LocationTracker = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const shouldTrack = ALLOWED_PATHS.includes(pathname);
  const { error } = useSelector((state) => state.auth.location);
  const {
    speed,
    latitude,
    longitude,
    error: locationError,
  } = useGeolocation({
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 12000,
  });

  useEffect(() => {
    if (!shouldTrack) return;

    if (locationError) {
      const errorMessage = {
        code: locationError.code,
        message: locationError.message,
      };

      dispatch(setLocationError(errorMessage));

      if (locationError.code === 1) {
        toast.error(
          'Location permission denied. Please enable location access to use this feature.'
        );
      } else if (locationError.code === 2) {
        toast.error('Unable to determine your location. Please check your device settings.');
      } else {
        toast.error('Error accessing location. Please try again later.');
      }
    } else if (latitude !== undefined && longitude !== undefined) {
      dispatch(
        setLocation({
          speed,
          latitude,
          longitude,
        })
      );
    }
  }, [latitude, longitude, speed, locationError, dispatch, shouldTrack]);

  const handleRequestPermission = async () => {
    try {
      navigator.geolocation.getCurrentPosition(
        () => {
          dispatch(setLocationError(null));
        },
        () => {}
      );
    } catch (err) {
      console.error('Error requesting location permission:', err);
      toast.error('Failed to request location permission');
    }
  };

  if (shouldTrack && error?.code === 1) {
    return (
      <div className="loading-overlay">
        <button onClick={handleRequestPermission} className="btn-md btn-primary">
          {t('Press to Enable Location Access')}
        </button>
      </div>
    );
  }

  return null;
};

export const withLocationTracking = (WrappedComponent) => {
  return function WithLocationTracking(props) {
    return (
      <>
        <LocationTracker />
        <WrappedComponent {...props} />
      </>
    );
  };
};
