import { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { CgSpinner } from 'react-icons/cg';
import { useTranslation } from 'react-i18next';

import DPCoin from './DPCoin';
import SlopeCard from './SlopeCard';
import SlopeButton from './SlopeButton';

const TaskItem = ({
  id,
  icon,
  title,
  points,
  isCompleted,
  condition,
  onClaim,
  bonus,
  daily = false,
  highlight = false,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [countdown, setCountdown] = useState(10);

  const handleClaimClick = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setShowWarning(true);
    }, 2000);

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const handleCheckClick = () => {
    setIsLoading(true);

    onClaim(id);

    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  };

  const handleCondition = () => {
    if (condition?.url) {
      if (condition.url.startsWith('https://t.me/')) {
        Telegram.WebApp.openTelegramLink(condition.url);
      } else {
        window.Telegram.WebApp.openLink(condition.url);
      }
    }
  };

  return (
    <SlopeCard
      highlight={highlight}
      leftContent={
        <div className={'task-item'}>
          <div className="task-icon">
            {bonus > 0 && <span className="task-icon-bonus">{bonus}X</span>}
            {typeof icon === 'string' ? (
              <img src={icon === 'logo' ? '/logo_aikido.webp' : icon} alt={title} />
            ) : (
              icon
            )}
          </div>
        </div>
      }
      rightContent={
        <>
          <div className={'task-item'}>
            <div className="task-details">
              <span className="task-title">{title}</span>
              <div className="task-points">
                {daily ? <small>{points}</small> : <DPCoin points={points} />}
              </div>
            </div>

            <div className="task-action">
              {!isCompleted ? (
                isLoading ? (
                  <CgSpinner size={30} className="task-spinner" />
                ) : countdown === 10 ? (
                  <SlopeButton
                    variant="secondary"
                    size="sm"
                    onClick={
                      title === t('Daily Login Reward') ? handleCheckClick : handleClaimClick
                    }
                    disabled={isCompleted}
                  >
                    {t('Claim')}
                  </SlopeButton>
                ) : (
                  <SlopeButton
                    variant="secondary"
                    size="sm"
                    onClick={handleCheckClick}
                    disabled={countdown > 0 || isLoading}
                  >
                    {countdown > 0 ? t('Check {{countdown}}s', { countdown }) : t('Check')}
                  </SlopeButton>
                )
              ) : (
                <FaCheck size={30} className="color-accent-secondary" />
              )}
            </div>
          </div>
          {showWarning && !isCompleted && (
            <p className="task-warning">
              {t('Activity not confirmed. Are you sure you completed the task?')}
            </p>
          )}
        </>
      }
      onClick={handleCondition}
    ></SlopeCard>
  );
};

export default TaskItem;
