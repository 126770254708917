import { Link, useLocation } from 'react-router-dom';

import '../assets/styles/BottomNavBar.css';

const BottomNavBar = () => {
  const location = useLocation();

  return (
    <div className="bottom-nav-bar">
      <Link
        id="navbar-button-map"
        to="/map"
        className={`nav-button ${location.pathname === '/map' ? 'nav-button-active' : ''}`}
      >
        <img src="/icon_map.svg" alt="map" className="nav-button-icon" />
        {/* <span>Map</span> */}
      </Link>
      <Link
        id="navbar-button-camera"
        to="/camera"
        className={`nav-button ${location.pathname === '/camera' ? 'nav-button-active' : ''}`}
      >
        <img src="/icon_camera.svg" alt="camera" className="nav-button-icon" />
        {/* <span>Drive & Earn</span> */}
      </Link>
      <Link
        to="/bonus"
        className={`nav-button ${location.pathname === '/bonus' ? 'nav-button-active' : ''}`}
      >
        <img
          id="navbar-button-bonus"
          src="/icon_home.svg"
          alt="bonus"
          className="nav-button-icon"
        />
        {/* <span>Bonus</span> */}
      </Link>
      <Link
        to="/leaderboard"
        className={`nav-button ${location.pathname === '/leaderboard' ? 'nav-button-active' : ''}`}
      >
        <img src="/icon_trophy.svg" alt="trophy" className="nav-button-icon" />
        {/* <span>Leaderboard</span> */}
      </Link>
      <Link
        id="navbar-button-friends"
        to="/friends"
        className={`nav-button friends-animation ${
          location.pathname === '/friends' ? 'nav-button-active' : ''
        }`}
      >
        <img src="/icon_users.svg" alt="friends" className="nav-button-icon" />
        {/* <span>Friends</span> */}
        {/* <div className="bonus-badge bonus-badge-animation">2x BONUS</div> */}
      </Link>
    </div>
  );
};

export default BottomNavBar;
