import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Walkthrough from '../components/Walkthrough';
import SlopeButton from '../components/SlopeButton';

import '../assets/styles/SnapMatchIntro.css';

const SnapMatchTip = () => {
  const { t } = useTranslation();

  return (
    <div className="snapmatch-tip top-left-box">
      {/* <div className="snapmatch-tip-content"> */}
      <div className="tip-item-container">
        <div className="tip-item">
          <img src="/icon_wifi.svg" alt={t('wifi')} className="tip-item-icon" />

          <div>
            <h3>{t('Work from Anywhere')}</h3>
            <p>{t('You can review and rate images right from anywhere!')}</p>
          </div>
        </div>
        <div className="tip-item">
          <img src="/icon_bonus.svg" alt={t('bonus')} className="tip-item-icon" />

          <div>
            <h3>{t('Earn Rewards')}</h3>
            <p>{t('The closer your ratings are to others, the more points you rack up.')}</p>
          </div>
        </div>
        <div className="tip-item">
          <img src="/icon_low_energy.svg" alt={t('low_energy')} className="tip-item-icon" />

          <div>
            <h3>{t('Energy System')}</h3>
            <p>
              {t(
                'Start with 1000 Snapping Energy. Each SnapMatch costs 50 Energy. Your energy regenerates automatically'
              )}
            </p>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

const SnapMatchIntro = () => {
  const { t } = useTranslation();
  const [showWalkthrough, setShowWalkthrough] = useState(false);

  const joyrideSnapMatchIntroSteps = [
    {
      target: '#snapmatch-start',
      route: '/snapmatch-intro',
      placement: 'bottom',
      title: t('What is SnapMatch?'),
      content: (
        <>
          {t(
            'With SnapMatch., you can validate and rate other users’ road images, helping train the AI.'
          )}
          <br />
          <br />
          {t(
            'And yes, you’ll earn rewards for every rating! The more accurate your ratings, the more you earn.'
          )}
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
      hideFooter: true,
    },
  ];

  useEffect(() => {
    const snapmatchIntroWalkthrough = localStorage.getItem('snapmatchIntroWalkthrough') === null;
    if (snapmatchIntroWalkthrough) {
      localStorage.setItem('snapmatchIntroWalkthrough', 'false');
      setShowWalkthrough(true);
    }
  }, []);

  return (
    <div className="snapmatch-intro-container">
      {showWalkthrough && (
        <Walkthrough
          key="snapmatch-intro"
          steps={joyrideSnapMatchIntroSteps}
          place="snapmatch-intro"
        />
      )}
      <div className="snapmatch-intro-body">
        <h1
          dangerouslySetInnerHTML={{
            __html: t('Welcome to SnapMatch!').replace(
              'SnapMatch',
              '<span class="text-accent-primary">SnapMatch</span>'
            ),
          }}
        />
        <p>{t('Help improve AI by rating road events captured by fellow Dashpoolers.')}</p>

        <div className="snapmatch-intro-action">
          <Link id="snapmatch-start" to="/snapmatch" className="">
            <SlopeButton size="lg" highlight>
              {t('Start SnapMatch')}
            </SlopeButton>
          </Link>
        </div>

        <SnapMatchTip />
      </div>
    </div>
  );
};

export default SnapMatchIntro;
