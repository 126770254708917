import { useTranslation } from 'react-i18next';

import '../assets/styles/ConfirmModal.css';
import SlopeButton from './SlopeButton';

const ConfirmModal = ({ isOpen, onClose, onConfirm, title, message }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="modal-actions">
          <SlopeButton onClick={onClose} size="sm">
            {t('Cancel')}
          </SlopeButton>
          <SlopeButton onClick={onConfirm} size="sm" variant="secondary">
            {t('Confirm')}
          </SlopeButton>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
