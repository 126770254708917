import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
  FaMapMarkerAlt,
  FaRoad,
  FaBuilding,
  FaCity,
  FaGlobe,
  FaMapMarker,
  FaArrowRight,
  FaLongArrowAltRight,
} from 'react-icons/fa';
import { FiMapPin } from 'react-icons/fi';

import '../assets/styles/IncidentCard.css';

const IncidentCard = ({
  incident,
  expandedIncident,
  handleCardClick,
  addressDetails,
  showOnMap,
}) => {
  return (
    <div
      key={incident._id}
      className={`incident-card ${expandedIncident === incident._id ? 'expanded' : ''}`}
      onClick={() => handleCardClick(incident)}
    >
      <div className="incident-header">
        <span className="incident-summary">{new Date(incident.createdAt).toLocaleString()}</span>
        <span className="incident-arrow">
          {expandedIncident ? (
            <IoIosArrowDown size={15} className="text-accent-primary" />
          ) : (
            <IoIosArrowUp size={15} className="text-accent-primary" />
          )}
        </span>
      </div>
      <div className="incident-description">
        <p>{incident.response.processed_text}</p>
      </div>
      <div className="city-name">
        <FiMapPin className="text-accent-primary" size={20} style={{ marginRight: '10px' }} />{' '}
        {addressDetails?.city === 'Locating the event' ? (
          <span>Locating...</span>
        ) : (
          <small>{`${addressDetails.city}, ${addressDetails.province}, ${addressDetails.country}`}</small>
        )}
      </div>
      <div className={`incident-details ${expandedIncident === incident._id ? 'visible' : ''}`}>
        {addressDetails?.city === 'Locating the event' ? (
          <p className="text-light">Loading...</p>
        ) : (
          <div className="address-details">
            <p>
              <FaRoad /> Road: {addressDetails.road}
            </p>
            <p>
              <FaBuilding /> Suburb: {addressDetails.suburb}
            </p>
            <p>
              <FaCity /> State/Province: {addressDetails.province}
            </p>
            <p>
              <FaBuilding /> Postcode: {addressDetails.postcode}
            </p>
            <p>
              <FaGlobe /> Country: {addressDetails.country}
            </p>
            <p>[ {incident.location.coordinates.join(', ')} ]</p>
            <button className="show-map-button" onClick={() => showOnMap(incident)}>
              Show on the Map <FaLongArrowAltRight size={20} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default IncidentCard;
