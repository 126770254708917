import { useTranslation } from 'react-i18next';

import '../assets/styles/LowSpeedOverlay.css';

const SPEED_THRESHOLD = parseInt(import.meta.env.VITE_SPEED_THRESHOLD);

const LowSpeedOverlay = ({ currentSpeed, autoCapture }) => {
  const { t } = useTranslation();

  return (
    <div className="overlay">
      <div className="overlay-content">
        {/* <h1 className='overlay-title'>Speed too low</h1> */}
        <p className="overlay-message">
          {t("We'll DashPool when speed is +{{speed}} km/h", { speed: SPEED_THRESHOLD })}{' '}
          {autoCapture && (
            <span>
              {t('(Current: {{currentSpeed}} km/h)', { currentSpeed: currentSpeed.toFixed(1) })}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default LowSpeedOverlay;
