import axios from 'axios';
import Webcam from 'react-webcam';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { FaPlay } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRef, useState, useEffect } from 'react';
import { IoIosInformationCircle } from 'react-icons/io';

import Walkthrough from '../components/Walkthrough';
import SlopeButton from '../components/SlopeButton';
import LowSpeedOverlay from '../components/LowSpeedOverlay';
import { withLocationTracking } from '../components/LocationTracker';

import '../assets/styles/Camera.css';

const API_URL = import.meta.env.VITE_API_URL;
const APP_NAME = import.meta.env.VITE_APP_NAME;

const SPEED_THRESHOLD = parseInt(import.meta.env.VITE_SPEED_THRESHOLD);

const Camera = () => {
  const { t } = useTranslation();
  const webcamRef = useRef(null);
  const { tg } = useSelector((state) => state.auth);
  const location = useSelector((state) => state.auth.location);
  const [image, setImage] = useState(null);
  const [lowSpeed, setLowSpeed] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [currentSpeed, setCurrentSpeed] = useState(0);
  const [autoCapture, setAutoCapture] = useState(false);
  const [captureDistance, setCaptureDistance] = useState(25);
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const [inRestricted, setInRestricted] = useState(null);
  const [previousLocation, setPreviousLocation] = useState(null);
  const [accumulatedDistance, setAccumulatedDistance] = useState(0);

  useEffect(() => {
    if (location && location.latitude && location.longitude) {
      if (isInRestricted(location.latitude, location.longitude)) {
        setInRestricted(true);
      } else {
        setInRestricted(false);
      }
    }
  }, [location]);

  const isInRestricted = (lat, lon) => {
    return lat >= 44.3864 && lat <= 52.3791 && lon >= 22.1371 && lon <= 40.2276;
  };

  useEffect(() => {
    const isFirstVisit = sessionStorage.getItem('isFirstVisit') === null;
    if (isFirstVisit) {
      sessionStorage.setItem('isFirstVisit', 'false');
      setShowPopup(true);
    }
  }, []);
  useEffect(() => {
    const cameraWalkthrough = localStorage.getItem('cameraWalkthrough') === null;
    if (cameraWalkthrough) {
      localStorage.setItem('cameraWalkthrough', 'false');
      setShowWalkthrough(true);
    }
  }, []);

  useEffect(() => {
    if (!autoCapture || inRestricted) {
      return;
    }

    if (!location || location.latitude == null || location.longitude == null) {
      setCurrentSpeed(0);
      setLowSpeed(true);
      setCaptureDistance(Infinity);
      return;
    }

    const speedMs = location.speed != null ? location.speed : 0;
    const speedKmH = speedMs * 3.6;
    setCurrentSpeed(speedKmH);

    if (speedKmH < SPEED_THRESHOLD) {
      setCaptureDistance(Infinity);
      setLowSpeed(true);
      return;
    }

    setLowSpeed(false);

    if (speedKmH >= SPEED_THRESHOLD && speedKmH < 30) {
      setCaptureDistance(25);
    } else if (speedKmH >= 30 && speedKmH < 60) {
      setCaptureDistance(50);
    } else if (speedKmH >= 60 && speedKmH < 90) {
      setCaptureDistance(100);
    } else {
      setCaptureDistance(200);
    }

    if (previousLocation) {
      const distance = getDistance(
        previousLocation.latitude,
        previousLocation.longitude,
        location.latitude,
        location.longitude
      );

      if (!isNaN(distance)) {
        const totalDistance = accumulatedDistance + distance;

        if (totalDistance >= captureDistance) {
          capture(totalDistance);
          setAccumulatedDistance(0);
        } else {
          setAccumulatedDistance(totalDistance);
        }
      }
    }

    setPreviousLocation({ latitude: location.latitude, longitude: location.longitude });
  }, [
    location.latitude,
    location.longitude,
    location.speed,
    autoCapture,
    inRestricted,
    captureDistance,
  ]);

  const getDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3;
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a = Math.sin(Δφ / 2) ** 2 + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  const capture = async (distance) => {
    if (inRestricted) return;
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);

    await uploadImage(imageSrc, distance);
  };

  const uploadImage = async (imageSrc, distance) => {
    if (!imageSrc || inRestricted) return;

    try {
      const currentLatitude = location.latitude;
      const currentLongitude = location.longitude;

      const blob = await fetch(imageSrc).then((res) => res.blob());
      const formData = new FormData();
      formData.append('file', blob, 'image.jpg');
      formData.append('latitude', currentLatitude);
      formData.append('longitude', currentLongitude);
      formData.append('tg_id', tg.id);
      formData.append('distance', distance);

      await axios.post(`${API_URL}/incidents/process-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      Sentry.captureException(error);
      toast.error('Error uploading the image');
    }

    setImage(null);
  };

  const handleAutoCaptureToggle = async () => {
    if (inRestricted) {
      toast.error(t('The camera feature is not available in your region.'));
      return;
    }
    setAutoCapture(!autoCapture);
    setAccumulatedDistance(0);
    setPreviousLocation(null);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleQuestionClick = () => {
    setShowPopup(true);
  };

  const joyrideCameraIntroSteps = [
    {
      target: '#navbar-button-camera',
      route: '/camera',
      placement: 'center',
      title: t('What is Dashpooling?'),
      content: (
        <>
          {t('Dashpooling lets you capture real-world road data while you travel.')}
          <br />
          <br />
          {t(
            'This helps our AI map events in real-time, and you earn rewards for every contribution.'
          )}
          <br />
          <br />
          {t('To start, tap the camera icon in the menu.')}
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: '.camera-controls',
      route: '/camera',
      placement: 'bottom',
      title: t('How to Dashpool?'),
      content: (
        <>
          {t(
            "Whether you're driving, biking, or riding a motorbike, place your phone in a position where it captures the road clearly."
          )}
          <br />
          <br />
          {t('Make sure at least 85% of the image is focused on the road.')}
          <br />
          <br />
          {t('Dashpooling starts automatically when your speed exceeds 10 km/h.')}
        </>
      ),
      disableBeacon: true,
      // spotlightClicks: true,
    },
    {
      target: '#navbar-button-map',
      route: '/camera',
      placement: 'bottom',
      title: t('Explore the Map'),
      content: (
        <>
          {t(
            'On the map, you’ll see live road events like traffic, accidents, and more within a 25 km radius.'
          )}
          <br />
          <br />
          {t(
            'Tap any event to view details and find out how you can contribute your own data to improve AI and earn rewards!'
          )}
          {/* <br />
          <br />
          <div
            style={{
              display: 'grid',
              gap: '12px',
              marginLeft: '-10px',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridTemplateRows: 'repeat(3, auto)',
            }}
          >
            <Link className="btn-primary btn-sm" to="/map">
              {t('Explore Map')}
            </Link>
            <Link className="btn-primary btn-sm" to="/camera" onClick={}>
              {t('Start DashPooling')}
            </Link>
          </div> */}
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
      hideFooter: true,
    },
  ];

  if (inRestricted === null || inRestricted === true) {
    return (
      <div className="location-message">
        {inRestricted === null
          ? t('Checking your location...')
          : t('The camera feature is not available in your region.')}
      </div>
    );
  }

  return (
    <div>
      {showWalkthrough && <Walkthrough steps={joyrideCameraIntroSteps} place="camera" />}

      <IoIosInformationCircle className="question-icon" onClick={handleQuestionClick} />

      {lowSpeed && !showPopup && (
        <LowSpeedOverlay currentSpeed={currentSpeed} autoCapture={autoCapture} />
      )}

      {showPopup && (
        <div className="tip">
          <div className="tip-content top-left-box">
            <p className="tip-title">{t('Best {{appName}} Experience', { appName: APP_NAME })}</p>

            <div className="tip-item-container">
              <div className="tip-item">
                <img src="/icon_camera.svg" alt={t('camera')} className="tip-item-icon" />

                <p>{t('Make sure your phone’s camera is on and capturing the road clearly.')}</p>
              </div>
              <div className="tip-item">
                <img src="/icon_target.svg" alt={t('target')} className="tip-item-icon" />

                <p>
                  {t(
                    'Keep 85% of the image focused on the road, with only a bit of the dashboard showing.'
                  )}
                </p>
              </div>

              <div className="tip-item">
                <img src="/icon_bonus.svg" alt={t('bonus')} className="tip-item-icon" />

                <p>{t('Position your phone carefully to earn the most rewards!')}</p>
              </div>
            </div>

            <SlopeButton id="tip-ok-button" onClick={handlePopupClose}>
              <h3>{t('Got it!')}</h3>
            </SlopeButton>
          </div>
        </div>
      )}
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        className="camera"
        videoConstraints={{ facingMode: 'environment' }}
      />
      {!showPopup && (
        <div className="camera-controls">
          <SlopeButton
            variant="primary"
            size="lg"
            fullWidth
            highlight={autoCapture}
            onClick={handleAutoCaptureToggle}
          >
            <div style={{ display: 'flex', alignItems: 'start' }}>
              {!autoCapture && <FaPlay size={20} style={{ marginRight: '10px' }} />}
              {autoCapture && <span className="blinking-circle"></span>}
              {autoCapture ? t('Stop') : t('Start')}
            </div>
          </SlopeButton>
        </div>
      )}
      {image && <img src={image} alt={t('Captured')} className="captured-image" />}
    </div>
  );
};

export default withLocationTracking(Camera);
