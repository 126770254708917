export const LevelConfigurations = [
  {
    level: 1,
    color: '#fff',
    pointsNeeded: 0,
    name: 'White Belt',
    unlockedFeatures: ['annotation', 'mappingCar'],
    actionLimitPerDay: 280,
    multiplier: 1.0,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 2,
    color: '#faee1c',
    pointsNeeded: 100,
    name: 'Yellow Belt',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.05,
    mechanics: [],
    additionalRequirements: ['Twitter subscription'],
  },
  {
    level: 3,
    color: '#eb5234',
    pointsNeeded: 500,
    name: 'Orange Belt',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.1,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 4,
    color: '#eb5234',
    pointsNeeded: 1000,
    name: 'Orange Belt Advanced',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.15,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 5,
    color: '#166f47',
    pointsNeeded: 2000,
    name: 'Green Belt',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.2,
    mechanics: ['Squads'],
    additionalRequirements: ['Survey', 'Tg subscription', 'Other'],
  },
  {
    level: 6,
    color: '#166f47',
    pointsNeeded: 3000,
    name: 'Green Belt Advanced',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.25,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 7,
    color: '#166f47',
    pointsNeeded: 4000,
    name: 'Green Belt Expert',
    unlockedFeatures: ['prompt engineering (1st lvl complexity)'],
    actionLimitPerDay: 280,
    multiplier: 1.3,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 8,
    color: '#2eb0db',
    pointsNeeded: 5000,
    name: 'Blue Belt',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.35,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 9,
    color: '#2eb0db',
    pointsNeeded: 6000,
    name: 'Blue Belt Advanced',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.4,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 10,
    color: '#4f2d19',
    pointsNeeded: 6500,
    name: 'Brown Belt',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.45,
    mechanics: ['Guilds', 'Skill tree'],
    additionalRequirements: ['1000 for Image recognition', '500 for annotations'],
  },
  {
    level: 11,
    color: '#4f2d19',
    pointsNeeded: 8000,
    name: 'Brown Belt Advanced',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.5,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 12,
    color: '#000',
    pointsNeeded: 10000,
    name: 'Black Belt Apprentice',
    unlockedFeatures: ['mappingWalking'],
    actionLimitPerDay: 280,
    multiplier: 1.55,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 13,
    color: '#000',
    pointsNeeded: 12500,
    name: 'Black Belt Apprentice Advanced',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.6,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 14,
    color: '#000',
    pointsNeeded: 15000,
    name: 'Black Belt Apprentice Expert',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.65,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 15,
    color: '#CD7F32',
    pointsNeeded: 20000,
    name: 'Shodan (First-degree Black Belt)',
    unlockedFeatures: ['prompt engineering (2nd lvl complexity)'],
    actionLimitPerDay: 280,
    multiplier: 1.7,
    mechanics: ['Coach'],
    additionalRequirements: [],
  },
  {
    level: 16,
    color: '#CD7F32',
    pointsNeeded: 25000,
    name: 'Shodan Advanced',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.75,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 17,
    color: '#CD7F32',
    pointsNeeded: 30000,
    name: 'Shodan Expert',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.8,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 18,
    color: '#C0C0C0',
    pointsNeeded: 35000,
    name: 'Nidan (Second-degree Black Belt)',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.85,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 19,
    color: '#C0C0C0',
    pointsNeeded: 40000,
    name: 'Nidan Advanced',
    unlockedFeatures: [],
    actionLimitPerDay: 280,
    multiplier: 1.9,
    mechanics: [],
    additionalRequirements: [],
  },
  {
    level: 20,
    color: '#FFD700',
    pointsNeeded: 50000,
    name: 'Master Sensei',
    unlockedFeatures: ['data sets gathering and provision'],
    actionLimitPerDay: 280,
    multiplier: 2.0,
    mechanics: ['Leagues', 'AI Expert', 'Requests Marketplace'],
    additionalRequirements: [],
  },
];
