import '../assets/styles/SlopeBox.css';

const SlopeBox = ({ children, highlight = false, ...props }) => {
  return (
    <div className={`slope-box ${highlight ? 'highlight' : ''}`} {...props}>
      {children}
    </div>
  );
};

export default SlopeBox;
