import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: false,
  uploads: {
    image: 0,
    video: 0,
  },
  _id: '',
  wallet: '',
  score: 0,
  points: 0,
  lastUpload: '',
  claimedLevels: [],
  devMode: false,
  level: 1,
  multiplier: 1,
  unlockedFeatures: [],
  location: {
    speed: null,
    latitude: null,
    longitude: null,
    error: null,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
    setUserData: (state, action) => {
      return { ...state, ...action.payload };
    },
    setDevMode: (state, action) => {
      state.devMode = action.payload;
    },
    setLocation: (state, action) => {
      state.location = {
        ...state.location,
        ...action.payload,
        error: null,
      };
    },
    setLocationError: (state, action) => {
      state.location = {
        ...state.location,
        error: action.payload,
      };
    },
  },
});

export const { setAuth, setUserData, setDevMode, setLocation, setLocationError } =
  authSlice.actions;

export default authSlice.reducer;
